import React from 'react'
import Tabs from "../../components/tabs"
type Props = {
    children?: any;
}

function LegalInfo({
    children
}: Props) {
    return (
        <div className='-max-w-1366 mx-auto'>
            <div className='py-16 px-2 md:px-0'>
                <div style={{
                    border: "1px solid #f1f1f1"
                }}>
                    <Tabs >
                        {/* <div title="Criteria">
                            It will simply take a couple of minutes of your time to finish your loan offer form. We are committed to helLending Groundu secure the loan amount you require and can usually tell you the result of your application straight away. Securing you the funds you require, when you require them most.<br /><br />
                            We don't have to know your life history, only a couple of vital details; you should be more than 18, have a regular salary, have a permanent address and, hold a legitimate checking account to be qualified to apply. Likewise depending upon your residential state may confine the loan lenders accessible that could offer you financing. There will be no requirement for an up close and personal gathering, a phone meet or for you to present any paperwork.
                        </div>
                        <div title="APR">
                            thelendingtree.co.nz refers consumers to trusted, reputable lenders who are able to provide loan information and advice – we do not charge for this service.
                            <br /><br />
                            thelendingtree.co.nz is not a lender. Because of this, we are not able to confirm the exact rate of APR that you will be charged. Should you have reached our site via a paid Google advert, you will have seen repayment terms ranging from 6 to 60 months, together with illustrated rates of no greater than 25.95% APR (calculation consistent with the Truth In Lending Act, TILA).
                            <br /><br />
                            To apply for a loan, you should be 18 years or older. The rate of APR you will be charged will be set by the moneylender. The figure can differ and, will be based both on the information that you provide to the lender within your loan request and the data the loan provider supplied to you. The lender will always notify you of the APR they can offer you.
                            <br /><br />
                            APR rates are based on how creditworthy you are, the rates are subject to change without notice which could result in the rate and amount you pay back varying.
                            <br /><br />
                            Once your application has been acknowledged by a moneylender, they will provide you with all the facts associated with the loan. This will incorporate the APR, any related loan finance charges and all of the terms you will be agreeing to. It is prudent that you read all information submitted to you by the lender to ensure that you can make an informed decision prior to accepting a loan offer.
                            <br /><br />
                            If you have any questions related to APR or loan related services, please contact us for more assistance.
                        </div>
                        <div title="Representative's Example">
                            If you borrowed $2,000 over a 12 month period and the loan had a 3% arrangement fee ($60), your monthly repayments would be $189.12, with a total payback amount of $2269.44 which including the 3% fee paid from the loan amount, would have a total cost of $329.44. Representative 29.82% APR.
                            <br /><br />
                            If you borrowed $5,000 over a 48 month period and the loan had an 8% arrangement fee ($400), your monthly repayments would be $131.67, with a total payback amount of $6320.12 which including the 8% fee paid from the loan amount, would have a total cost of $1720.12. Representative 18.23% APR.
                        </div>
                        <div title='Disclosure'>
                            <strong>
                                Lender Network Disclosure :
                            </strong>
                            <br /><br />
                            thelendingtree.co.nz is not a lender – we provide no loans, make no lending decisions, nor do we influence lenders in their decisions. We pride ourselves in providing a free, no-obligation service to consumers looking to borrow funds. We connect those very people, people like you, to trusted, reputable lenders who are willing to lend to them.
                            <br /><br />
                            When using thelendingtree.co.nz to secure your loan requirements, you’ll benefit from our bespoke financial technology. Not only will you be able to access a network of lenders quickly and with ease with just one application, we’ll filter out the companies that don’t match your needs or personal circumstances.
                            <br /><br />
                            All of the lenders in our network employ their own unique lending criteria and have individual terms and conditions. With this in mind, when you’re approved for a loan and receive your no obligation loan offer, be sure to read the documents carefully to ascertain that it’s the right offer for you! Remember, you’re not obliged to proceed.

                            <br /><br />
                            <strong>
                                Credit Check Disclosure :
                            </strong>
                            <br /><br />
                            thelendingtree.co.nz are not lenders so make NO loan or credit decisions. The trusted lenders that we work with may, however, carry out credit checks to ascertain how creditworthy you are, your credit standing and/or your credit capacity. There are three reporting credit bureaus that the credit checks may be carried out with: Experian, Equifax, and TransUnion. By submitting your loan request, you are agreeing to allow our lenders to verify your personal information and check your credit.
                            <br /><br />
                            Please borrow responsibly and remember that a missed or late payment can have a negative credit impact.
                            <br /><br />

                            <strong>
                                Truth in Lending Policy :
                            </strong>
                            <br /><br />
                            To work with thelendingtree.co.nz, the lenders in our network must all comply with to the Truth in Lending Act. This means that they always supply loan offers and contractual agreements that are easily understood as best practice and as required by Federal Law. Truth in Lending helps protect you the consumer against predatory and unscrupulous lending.
                            <br /><br />
                            Please remember the choice to proceed with a loan is all yours, you are under no obligation to accept a loan offer. Before agreeing to go ahead with the agreement, please read all of the paperwork the lender provides you with care to ensure that you understand all of the information about your loan (APR, charges etc). You also need to consider and be certain that you can afford the repayments, and that you are totally satisfied with the offer that has been made to you before signing. If in doubt, ask the lender to clarify details. An informed choice is the best choice.
                            <br /><br />

                            <strong>
                                State Availability Disclosure :
                            </strong>
                            <br /><br />
                            The State availability of our services via our reputable lending network is entirely dependent on lenders that operate or offer their financial products and services within any particular state. Be aware that some lenders may charge APR rates that exceed the limit of the specific State Usury Law, because of this, they can’t legally operate within that State.
                            <br /><br />
                            The amount of interest that you can legally be charged is set by each State. These Usury Laws are there to protect consumers from unscrupulous lenders and excessive interest rates. Usury Laws are somewhat complicated by a multitude of legal loopholes and exceptions – resulting in it frequently becoming necessary for a rate for each situation.
                            <br /><br />
                            As a consumer, you have the right to waive the Usury Law set interest rates, this can be done via your contract with the lender. As a result, State Interest Rate Laws that apply to your State of residence may have no bearing on the actual rates you have agreed to pay.
                            <br /><br />
                            Lending criteria vary from State to State, this will mean that not all of our trusted lenders operate in every State. In fact, due to a lender’s eligibility criteria or State legislation, a lender has the option to decline your application and residents of some US states may not be eligible for instalment loans products at all.
                            <br /><br />
                            thelendingtree.co.nz connects consumers with lenders that adhere to the specific State Usury Laws and that charge (including fees and interest), a minimum APR rate of 3.95% to a maximum APR rate of 25.95%, but within the legal limits that have been set by that State. Responsibility for ensuring that any agreement you enter into meets State legislation is that of the lender.
                            <br /><br />
                            A useful point of contact regarding any concerns you may have regarding unlawful interest rates would be your State Office. They are best placed to advise you on the Usury Laws that will affect you.
                            <br /><br />
                            thelendingtree.co.nz does not undertake solicitation for loan products and does not constitute a loan offer for any loan products that are prohibited by State Law. This service is void where prohibited.
                        </div>
                        <div title="Implications">
                            <strong>
                                Late or Missed Payments :
                            </strong>
                            <br /><br />
                            When accepting the terms and conditions of your personal loan, you are agreeing to repay the loan both: A) with interest and B) in the time frame specified in the loan agreement. Generally, failure to repay the loan in full, making a late payment or, missing a payment, will result in you being charged additional fees by your lender.
                            <br /><br />
                            We have NO ability to predict or estimate what supplemental charges will be incurred in the event of late, partial, or non-payment of loans here at thelendingtree.co.nz. Nor have we any control or knowledge of any loan agreements or details between you and your lender.
                            <br /><br />
                            Within your Loan Agreement, you will be provided with details on the late, partial, and non-payment policies that your lender enforces, please review this fully prior to proceeding with a loan offer. thelendingtree.co.nz strives to partner with only the most trustworthy and reputable lenders, those who pursue the collection of past-due loan accounts in a fair and reasonable manner, ensuring you have the best loan experience.
                            <br /><br />

                            <strong>
                                Credit Score Impact :
                            </strong>
                            <br /><br />
                            To ascertain how creditworthy you are, your credit capacity and/or your credit standing, our network of lenders may perform credit checks. Submitting a loan request means that you are agreeing to allow our lenders to verify your personal information and check your credit. Note: missing payments and/or late payments may have a negative impact your credit score.
                            <br /><br />

                            <strong>
                                Collection Practices :
                            </strong>
                            <br /><br />
                            thelendingtree.co.nz only works with reputable lenders who use fair collection practices.
                            <br /><br />
                            As a non-lender, thelendingtree.co.nz has NO involvement in the debt collection process of your loan. Your lender will disclose all of their debt collection processes within your Loan Agreement. Should you have any questions regarding debt collections, please contact your loan provider.
                        </div> */}
                        <div >
                            <h4 className="my-4">

                                Annual Percentage Rate(APR) Disclosure &amp; Range(Qualified Customers)
                            </h4>

                            <p>
                                Annual percentage rate(APR) refers to the yearly interest generated by a sum that's charged to borrowers. APR is expressed as a percentage that represents the actual yearly cost of funds over the term of a loan. This includes any fees or additional costs associated with the transaction. The APR provides consumers with a bottom-line number they can compare among lenders, credit cards, or investment products. Lending-Ground cannot guarantee any APR since we are not a lender ourselves. An APR can generally run between 5.99% up to 35.99%. Loan products general have a 61-day minimum repayment term and a 84-month maximum repayment term. Before accepting a loan from a lender within our network, please read the loan agreement carefully as the APR and repayment terms may differ from what is listed on this site.</p>

                            <h4 className="my-4">
                                Representative Examples of APR, Total Loan Costs &amp; Fee(Qualified Customers)</h4>

                            <table className='w-full'>

                                <tbody>

                                    <tr className='font-bold'>

                                        <td>

                                            Amount
                                        </td>

                                        <td>

                                            Period</td>
                                        <td>
                                            APR</td>
                                        <td>
                                            Monthly</td>
                                        <td>
                                            Total Paid</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            $2,000</td>
                                        <td>
                                            12 mo</td>
                                        <td>
                                            24%</td>
                                        <td>
                                            $189.12</td>
                                        <td>
                                            $2,269.44</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            $4,000</td>
                                        <td>
                                            24 mo</td>
                                        <td>
                                            12%</td>
                                        <td>
                                            $188.29</td>
                                        <td>
                                            $4,518.96</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            $6,000</td>
                                        <td>
                                            36 mo</td>
                                        <td>
                                            12%</td>
                                        <td>
                                            $199.29</td>
                                        <td>
                                            $7,174.29</td>
                                    </tr>
                                </tbody>
                            </table>
                            <h4 className="my-4">
                                Financial Implications (Interest &amp; Finance Charges)</h4>
                            <p>
                                Lending-Ground is not a lender, and we cannot predict what fees and interest rates will be applied to any loan you may be offered.Your lender will provide all the necessary information about the associated costs of a loan they wish to offer you.You are responsible for reviewing the loan agreement carefully and accepting the offer only if you agree to all the terms. Lending-Ground does not charge you for its loan matching service, and you are under no obligation to accept the terms that the lender offers you.</p>
                            <h4 className="my-4">
                                Late Or Non-Payment Implications</h4>
                            <p>
                                By accepting the terms and conditions for a personal loan, you essentially agree to repay the loan both 1) with interest and 2) in the time frame specified in the loan agreement.In most cases, failure to repay the loan in full, or making a late payment, can result in additional charges.Lending-Ground.com has NO ability to predict or estimate what supplemental charges will be incurred in the event of late, partial, or non-payment.Lending-Ground.com also has NO control or knowledge of any loan agreements or details between you and your lender.</p>
                            <p>
                                Please carefully review the late, partial, and non-payment policies that your lender provides with your loan agreement. Lending-Ground.com works hard to partner with only the most trustworthy and reputable lenders who pursue the collection of past-due loan accounts in a fair and reasonable manner.</p>
                            <h4 className="my-4">
                                Potential Impact to Credit Score</h4>
                            <p>
                                Our lenders may perform credit checks to determine your credit worthiness, credit standing and/or credit capacity.By submitting your request you agree to allow our lenders to verify your personal information and check your credit.Please be aware that missing a payment or making a late payment can negatively impact your credit score.</p>
                            <h4 className="my-4">
                                Collection Practices</h4>
                            <p>
                                Lending-Ground.com is not a lender and, because of this, we have NO involvement in the debt collection process. As part of the lending agreement provided to you by the lender, they will disclose their debt collection practices. If you have any collection questions, please contact the lender for complete details. Lending-Ground.com only works with reputable lenders who use fair collection practices.</p>
                            <h4 className="my-4">
                                Loan Renewal Policies</h4>
                            <p>
                                Loan renewal options are not always available.It is therefore advisable to clarify whether the option is available with your lender. Before you sign the documents, carefully read and understand the renewal policy presented in the agreement.</p>

                            <h4 className="my-4">
                                Footnotes</h4>
                            <p>
                                (*) Three minutes is the average time taken to complete the online loan offer process, submit your details and receive a loan offer decision if approved.</p>
                            <p>
                                (**) Once approved, your cash could be sent within 15 minutes.The time that it takes for the cash to be received in your account will depend on your bank’s policies and procedures.</p>
                            <p>
                                (***) Although some providers offer rates from 4.95% up to 35.99% APR rates that low are only available to certain customers. The repayment terms are for close end loan products, and is not reflective of all loan products offered in our network.</p>
                        </div>

                    </Tabs>

                </div>
            </div>
        </div>
    )
}

export default LegalInfo